<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-text-field
          v-model="vm.buildingSociety"
          label="Bank / Building Society"
          v-validate="'required'"
          data-vv-as="Bank / Building society"
          name="buildingSociety"
          :error-messages="errors.collect('buildingSociety')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.accountName"
          label="Account Name"
          v-validate="'required'"
          data-vv-as="Account name"
          name="accountName"
          :error-messages="errors.collect('accountName')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.accountNumber"
          label="Account Number"
          v-validate="'required'"
          data-vv-as="Account number"
          name="accountNumber"
          :error-messages="errors.collect('accountNumber')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.bsbNumber"
          label="BSB Number"
          v-validate="'required'"
          data-vv-as=" BSB number"
          name="bsbNumber"
          :error-messages="errors.collect('bsbNumber')"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.superFundName"
          label="Super Fund Name"
          v-validate="'required'"
          data-vv-as="Super fund name"
          name="superFundName"
          :error-messages="errors.collect('superFundName')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.superMemberNumber"
          label="Super Member Number"
          v-validate="'required'"
          data-vv-as=" Super member number"
          name="superMemberNumber"
          :error-messages="errors.collect('superMemberNumber')"
        ></v-text-field>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
var Models = require("./models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "banking-form",
  data: () => ({
    vm: new Models.Banking(),
    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.banking == undefined
          ? {}
          : this.$store.getters.currentCase.banking;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.banking = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

